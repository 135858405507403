import "./style.scss";

import {
useCallback, useContext, // useEffect,
useState
} from "react";

import HeaderWithAccent from "../../Heading/_withAccentWords";
import { useImageRendering } from "../../Image";
import { LocaleContext } from "../../../layouts/LocaleContext";
import Paragraph from "../../Paragraph";
import PropTypes from "prop-types";
import SingleDetail from "../SingleDetail";
import Testimonial from "../../Testimonial";
import { useVideoRendering } from "../../Video";
import { createClassName } from "../../../helpers";

const cn = createClassName("media-details");

const ListDetails = (props) => {
  const Image = useImageRendering(props);
  const Video = useVideoRendering(props);
  const { isMobileOnly } = useContext(LocaleContext);
  const [openedIndex, openDetails] = useState(0);
  const id = `item-${props.index}`;
  // const itemRefList = [];

  // useEffect(() => {
  //   const videoRefListDesktop = Array.from(
  //     document.getElementsByTagName("video"),
  //   );
  //   const startPlayPosition = (window.innerHeight / 4) * 3;

  //   const startPlayVideo = (item, index) => {
  //     if (item.classList.contains("isOpen")) {
  //       if (
  //         item.getBoundingClientRect().top < startPlayPosition
  //         && item.getBoundingClientRect().bottom > 0
  //       ) {
  //         item.play();
  //         videoRefListDesktop.filter(
  //           (listItem, listIndex) => listIndex != index && listItem.pause(),
  //         );
  //       }
  //     } else item.pause();
  //   };

  //   videoRefListDesktop.map((item, index) => {
  //     startPlayVideo(item, index);
  //     document.addEventListener("scroll", () => startPlayVideo(item, index));
  //     return document.removeEventListener("scroll", () => startPlayVideo(item, index));
  //   });
  // }, [openedIndex]);

  const openHandler = useCallback((index) => {
    if (openedIndex === index) return;
    openDetails(index);
  });

  return (
    <div
      className={cn("block", {
        position: props.index % 2 === 0 ? "right" : "left",
      })}
      // ref={(ref) => itemRefList.push(ref)}
    >
      <div className={cn("block-description")}>
        {props.title}
        {props.mobileContent}

        <ul className={cn("list")}>
          {props.data.map((item, index) => {
            const isOpen = openedIndex === index;
            return (
              <li className={cn("list-item")} key={index}>
                <div className={cn("item")} id={`${id}-${index}`}>
                  {item.title ? (
                    <SingleDetail
                      title={item.title}
                      handler={() => openHandler(index)}
                      open={isOpen}
                      contentClassName={cn("item", { content: true })}
                      slideDownClassName={cn("list-slideDown")}
                    >
                      <Paragraph className={cn("item", { content: "text" })}>{item.content}</Paragraph>
                    </SingleDetail>
                  ) : (
                      <div>
                        {item.contentList.map((item, index) => (
                          <Paragraph key={index} className={cn("item", { content: "text" })} >{item}</Paragraph>
                        ))}
                      </div>
                    )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <ul className={cn("media-list")}>
        {props.data.map((item, index) => {
          const isOpen = openedIndex === index;
          return (
            <li
              className={cn("media-item", { image: !item.video })}
              aria-labelledby={`${id}-${index}`}
              key={index}
            >
              <div className={cn("content", { visibility: isOpen })}>
                {isMobileOnly || !item.video ? (
                  item.image && <Image src={item.image} alt={item.alt ?? "image"} />
                ) : (
                    <Video
                      sources={item.video}
                      isLoop
                      autoPlay
                      lazy
                      className={`${
                        isOpen ? "isOpen" : cn("media", { isClosed: true })
                        }`}
                    />
                  )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const ListDetailsWithMedia = (props) => {
  const Image = useImageRendering(props);
  const Video = useVideoRendering(props);
  return (
    <div className={cn("container")}>
      {props.data.map((item, index) => {
        const position = index % 2 === 0 ? "right" : "left";
        return (
          <div className={cn({ position })} key={index}>
            <div className={cn("text-content")}>
              <ListDetails
                title={(
                  <HeaderWithAccent
                    beforetitle={item.beforeHeading}
                    isComma={item.isHeadingComma}
                    level={2}
                    accentTitle={item.heading}
                    subTitle={item.afterHeading}
                    className={cn("title")}
                    accentColor="primary-main"
                  />
                )}
                mobileContent={
                  <div className={cn('text-content', { mobile: true })}>
                    <Image src={item.image} alt={item.alt ?? 'image'} />
                  </div>
                }
                index={props.index || index}
                data={item.featureList}
                imageRenderer={Image}
                videoRenderer={Video}
              />
            </div>
            {item.testimonial && item.featureList && (
              <div className={cn("testimonial", { position })}>
                <Testimonial
                  item={item.testimonial}
                  author={item.author}
                  skill={item.skill}
                  position="row"
                  isItalicStyle
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

ListDetailsWithMedia.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  index: PropTypes.number,
};

export default ListDetailsWithMedia;
