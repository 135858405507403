import "./style.scss";

import PropTypes from "prop-types";
import { createClassName } from "../../../helpers";

// import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("pricing");

const PricingValue = (props) => {
  // const { t } = useTranslation("common");
  const commentClassName = `${cn({ comment: true })} ${props.commentClassName}`;
  const integerClassName = `${cn({ numbers: "integer", sale: props.isSaleCompany && true })} ${props.integerClassName}`;
  // const integerClassName = `${cn({ numbers: "integer" })} ${props.integerClassName}`;
  const decimalClassName = `${cn({ numbers: "decimal" })} ${props.decimalClassName}`;
  const remarkClassName = `${cn({ remark: true })} ${props.remarkClassName}`;

  return (
    <div className={`${cn()} ${props.className}`}>
      <p className={commentClassName}>{props.comment}</p>
      <div className={cn({ numbers: true })}>
        <span className={integerClassName}>
          {`$${props.integer}`}
          <sup className={decimalClassName}>{props.decimal}</sup>
        </span>
        {props.additional}
      </div>
      <p className={remarkClassName}>
        {props.remark ? (
          props.remark
        ) : (
          <>
            {/* {t("user", { nonBreakingSpace: "\xa0" })}
            <wbr />
            {t("computer")} */}
            {props.remarkText}
          </>
        )}
      </p>
    </div>
  );
};

PricingValue.defaultProps = {
  integer: 14,
  decimal: 95,
  remark: "",
  className: "",
  isSaleCompany: false,
  comment: "",
  commentClassName: "",
  integerClassName: "",
  decimalClassName: "",
  remarkClassName: "",
};
PricingValue.propTypes = {
  comment: PropTypes.string,
  integer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  remark: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
  commentClassName: PropTypes.string,
  integerClassName: PropTypes.string,
  decimalClassName: PropTypes.string,
  remarkClassName: PropTypes.string,
  isSaleCompany: PropTypes.bool,
};

export default PricingValue;
