import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Product, { headerImagePreload } from "../../layouts/ProductPage";
import { imageManager, stdFluidImage } from "../../components/Image";

import { videoManager } from "../../components/Video";

const PopupCalendar = ({ data }) => {
  const { htmlAst, frontmatter } = data.markdownRemark;
  const { seo, toolkit, ...frontmatterData } = frontmatter;

  const headerImage = data.pageImages.nodes.find(({ relativePath }) => relativePath.match("__header."));

  return (
    <>
      <Helmet>
        {headerImage
          ? headerImagePreload(headerImage)
          : null}
      </Helmet>
      <div>
        <Product
          seo={seo[0]}
          {...frontmatterData}
          title={toolkit.title}
          featureList={toolkit.toolkitList}
          faq={htmlAst}
          imageRenderer={imageManager({
            nodes: [...data.pageImages.nodes, ...data.accentBannerImages.nodes],
          })}
          videoRenderer={videoManager(data.productVideos)}
          defaultDecimal={false}
        />
      </div>
    </>
  );
};

export default PopupCalendar;

export const query = graphql`
  query getPopupCalendarToolkit($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      frontmatter {
        seo {
          title
          description
          link
          image
        }
        bannerTitle
        isComma
        afterBannerTitle
        bannerSubTitle
        href
        icon
        alt
        price
        salePrice
        benefits {
          title
          description
        }
        toolkit {
          title
          toolkitList {
            video {
              src
              type
            }
            heading
            isHeadingComma
            afterHeading
            image
            testimonial
            author
            skill
            alt
            featureList {
              title
              image
              content
              alt
              video {
                src
                type
              }
            }
          }
        }
      }
    }
    pageImages: allFile(
      filter: {
        relativePath: { glob: "images/products/popup-calendar/**/*" }
        extension: { in: ["png", "jpg", "jpeg"] }
      }
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    accentBannerImages:allFile(
      filter:{
        relativeDirectory: { eq: "images"},
        name: { regex:"/^xltools-add-in-for-excel-ribbon/"}    
      }      
    ) {
      nodes {
        ...noBase64FluidImage
      }
    }
    productVideos:allFile(
      filter: {
        relativeDirectory: {
          eq: "video/products/popup-calendar"
        }
      }
    ) {
      nodes {
        relativePath
        publicURL
        extension
      }
    }
  }
`;
