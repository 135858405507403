import "./style.scss";

import { LazyImage, useImageRendering } from "../../Image";
import { useContext, useEffect, useState } from "react";

import AccentContainer from "../../Container/_accent";
import BuyLink from "../../Links/LinkOnResource/_buyLink";
import Container from "../../Container";
import Heading from "../../Heading";
import HeadingWithAccentWords from "../../Heading/_withAccentWords";
import LinkOnResource from "../../Links/LinkOnResource";
import { LocaleContext } from "../../../layouts/LocaleContext";
import Pricing from "../../Pricing/_value";
import PropTypes from "prop-types";
import { createClassName } from "../../../helpers";

import loadable from "@loadable/component";

// import Button from "../../Buttons/Common";
const cn = createClassName("accent-header-banner");

const AccentHeaderBanner = (props) => {
  const Image = useImageRendering(props);
  const { isMobile, isSaleCompany } = useContext(LocaleContext);
  const [SalePricingValue, setSalePricingValue] = useState(null);

  useEffect(() => {
    isSaleCompany && setSalePricingValue(loadable(() => import('../../../layouts/Sale/pricing')))
  }, []);

  return (
    <section aria-label={props.bannerTitle}>
      <AccentContainer>
        <Container className={cn({ wrap: true })}>
          <div className={cn("content", { wrap: true })}>
            <div className={cn("content", { text: true })}>
              <HeadingWithAccentWords
                level={1}
                accentTitle={props.bannerTitle}
                isComma={props.isComma}
                subTitle={props.bannerTitleAdditional}
                subTitleWeight="light"
                accentColor="white"
                subColor="white"
              />
              <Heading
                level={4}
                fontLevel={4}
                weight="light"
                className={cn("content", { description: true })}
              >
                {props.bannerSubTitle}
              </Heading>
              <div className={cn("pricing")}>
                <Pricing
                  className={cn("content", { price: "wrap" })}
                  remark={props.priceComment}
                  // comment={props.priceComment}
                  integer={props.price}
                  decimal={props.decimal}
                  integerClassName={cn("content", { price: true })}
                  isSaleCompany={isSaleCompany}
                  additional={
                    SalePricingValue && (
                      <SalePricingValue
                        salePrice={+props.salePrice}
                        defaultDecimal={props.defaultDecimal}
                      />
                    )
                  }
                />
              </div>
            </div>
            <div className={cn("image", { wrap: true })}>
              <div className={cn("image", { picture: true })}>
                <Image
                  src={props.icon}
                  native
                  eager
                  aria-label={props.icon}
                  aria-hidden="true"
                  alt={props.iconAlt}
                />
              </div>
            </div>
          </div>
          <div className={cn("button", { wrap: true })}>
            <BuyLink
              className={cn("button", { buy: true })}
              href={props.href}
            />
            <div>
              <LinkOnResource
                href="/download-free-trial/"
                isVolume
                color="white"
                className={cn("button", { trial: true })}
                isRel={false}
                withoutLocale={false}
              >
                {props.buttonTrialText}
              </LinkOnResource>
              {/* <Button
                className={cn("button", { trial: true })}
                size="large"
                handler={props.handler}
                ariaLabel={props.buttonTrialText}
              >
                {props.buttonTrialText}
              </Button> */}
              {!isMobile && (
                <pre className={cn("button", { trial: "tips" })}>
                  {props.priceTooltip}
                </pre>
              )}
            </div>
          </div>
        </Container>
      </AccentContainer>
    </section>
  );
};

AccentHeaderBanner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  buttonTrialText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonTrialColor: PropTypes.string,
  priceComment: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  integer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  decimal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handler: PropTypes.func,
  href: PropTypes.string,
  iconAlt: PropTypes.string.isRequired,
  salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default AccentHeaderBanner;
