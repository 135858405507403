import PropTypes from "prop-types";
import Icon from "./index";

const Cart = (props) => Icon({ ...props, iconName: "cart" });

Cart.defaultProps = {
  isAriaHidden: true,
  alt: "cart",
};

Cart.propTypes = {
  alt: PropTypes.string,
  isAriaHidden: PropTypes.bool,
  className: PropTypes.string,
};

export default Cart;
