import "./style.scss";

import { useContext, useEffect, useState } from "react";
import {
  arrowIcon,
  contactUs,
  faqDetail,
  faqHeading,
  insideLink,
  li,
  renderCom,
  toCheckout,
  ul,
} from "../../components/ForMarkDownComponents";

import AccentBanner from "../../components/AccentBanner";
import AccentHeaderBanner from "../../components/AccentBanner/AccentHeaderBanner";
import AccentSecondaryBanner from "../../components/AccentBanner/AccentSecondaryBanner";
import Benefits from "../../components/Benefits";
import BuyLink from "../../components/Links/LinkOnResource/_buyLink";
import Container from "../../components/Container";
import HMQ from "../../components/HaveMoreQuestionsBlock";
import Heading from "../../components/Heading";
import Layout from "..";
import ListDetailsWithMedia from "../../components/Details/ListDetailsWithMedia";
import { LocaleContext } from "../LocaleContext"
import Pricing from "../../components/Pricing/_value";
import PropTypes from "prop-types";
import StructureData from "../../components/StructuredData";
import Title from "../../components/Title";
import TranslationWord from "../../components/Translate_component";
import { createClassName } from "../../helpers";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import loadable from "@loadable/component";

const cn = createClassName("product");

const renderComToolkit = {
  h2: faqHeading,
  detail: faqDetail,
  ul,
  li,
  "inside-link": insideLink,
  "to-checkout": toCheckout,
  "contact-us": contactUs,
  arrow: arrowIcon,
};

const productRenderCom = renderCom(renderComToolkit);

export function headerImagePreload(headerImage) {
  if (!headerImage.childImageSharp) return null;
  const img = headerImage.childImageSharp.gatsbyImageData || headerImage.childImageSharp.gatsbyImageData;
  if (!img) return null;
  const { srcWebp: src, srcSetWebp: srcSet, sizes } = img;
  return <link rel="preload" as="image" href={src} imagesrcset={srcSet} imagesizes={sizes} />
}

const Product = (props) => {
  const [SalePricingValue, setSalePricingValue] = useState(null);
  const { siteLanguage, isSaleCompany } = useContext(LocaleContext);
  const { t } = useTranslation("common");

  useEffect(() => {
    isSaleCompany && setSalePricingValue(loadable(() => import('../Sale/pricing')))
  }, [])

  return (
    <>
      <Layout
        {...props.seo}
      >
        <AccentHeaderBanner
          {...props}
          bannerTitleAdditional={props.afterBannerTitle}
          buttonTrialText={
            <TranslationWord text="ft" />
          }
          // buttonTrialText={t("ft")}
          priceComment={
            <TranslationWord text="lifetime_license" />
          }
          // priceComment={t("lifetime-license", { nonBreakingSpace: "\xa0" })}
          decimal={95}
          priceTooltip={(
            <>
              {t("14-day")}
              {" "}
            &#8226;
            {" "}
              {t("without-card_required")}
            </>
          )}
          iconAlt={props.alt ? props.alt : null}
        //handler={handler}
        />
        <Benefits list={props.benefits} label={`${props.bannerTitle} benefits`} />
        <Container>
          <Title level={2} weight="light" align="center">
            {props.title}
          </Title>
          <ListDetailsWithMedia
            imageRenderer={props.imageRenderer}
            data={props.featureList}
            videoRenderer={props.videoRenderer}
          />

          {props.addition ? (
            <div className={cn({ addition: true })}>
              <Heading
                level={2}
                weight="light"
                align="center"
                children={props.additionTitle}
              />
              <ul className={cn({ feature: true })}>
                <ListDetailsWithMedia
                  data={props.addition}
                  index={props.featureList.length}
                  imageRenderer={props.imageRenderer}
                  videoRenderer={props.videoRenderer}
                />
              </ul>
            </div>
          ) : (
            ""
          )}
        </Container>
        <AccentBanner
          title={t("strings:AccentBanner.title", {
            bannerTitle: props.bannerTitle,
          })}
          titleClassName={cn({ accentBanner: "title" })}
          headingClassName={cn({ accentBanner: "title" })}
          buttonDescription={t("strings:AccentBanner.buttonDescription", {
            nonBreakingSpace: "\xa0",
          })}
          buttonClassName={cn({ accentBanner: "btn" })}
          imageRenderer={props.imageRenderer}
        >
          <div className="accent-header-banner__pricing">
            <Pricing
              integer={props.price}
              integerClassName={cn({ secondaryBanner: true })}
              decimalClassName={cn()}
              className={cn({ secondaryBanner: "price" })}
              remark=" "
              isSaleCompany
            />
            {SalePricingValue && (
              <SalePricingValue
                salePrice={+props.salePrice}
                integerClassName={cn({ secondaryBanner: true })}
                decimalClassName={cn()}
                defaultDecimal={props.defaultDecimal}
              />)}
          </div>
          <BuyLink href={props.href} />
        </AccentBanner>
        <Container className={cn("faq", { wrap: true })}>
          {productRenderCom(props.faq)}
          <HMQ className={cn({ hmq: true })} />
        </Container>
        <section aria-label="Get started in no time" id="get-started">
          <AccentSecondaryBanner
            subtitleClassName={cn({ secondaryBanner: "subtitle" })}
            buttonClassName={cn({ secondaryBanner: "start-trial" })} //delete for button
          //handler={handler}
          />
          {/* <Frame
          open={openFrame}
        /> */}
        </section>
      </Layout>
      <StructureData
        url={`/${props.seo.link}`}
        language={siteLanguage}
      />
    </>
  );
};

Product.defaultProps = {
  defaultDecimal: true,
}

Product.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  faq: PropTypes.object,
  featureList: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultDecimal: PropTypes.bool,
};

export default Product;
