import "./style.scss";

import PropTypes from "prop-types";
import Cart from "../../../Icons/Cart";
import LinkOnResource from "..";
import Title from "../../../Title";
import TranslationWord from "../../../Translate_component";
import { createClassName } from "../../../../helpers";

// import { useTranslation } from "react-i18next/dist/es/useTranslation";

const cn = createClassName("buyLink");

const BuyLink = (props) =>
  // const { t } = useTranslation("common");
   (
     <LinkOnResource
       className={cn({ wrap: true })}
       href={props.href}
       isTarget
     >
       <div className={cn({ content: true })}>
         <Title level={4} weight="medium" className={cn({ text: true })}>
           {props.text ? props.text : (
             <TranslationWord text="buy_now" />
          )}
           {/* {props.text ? props.text : t("buy-now")} */}
         </Title>
         <Cart className={cn("icon")} />
       </div>
     </LinkOnResource>
  );
BuyLink.defaultProps = {
  text: "",
};

BuyLink.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string.isRequired,
};

export default BuyLink;
